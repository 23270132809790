var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-2"
  }, [_c('h4', [_vm._v("Categories")]), _c('ul', {
    staticClass: "nav nav-pills flex-column push"
  }, _vm._l(_vm.settings, function (setting) {
    return _c('li', {
      key: setting._id,
      staticClass: "nav-item mb-1"
    }, [_c('a', {
      staticClass: "nav-link",
      class: {
        active: _vm.selectedSetting._id === setting._id
      },
      attrs: {
        "href": "#"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.onSelectSetting(setting._id);
        }
      }
    }, [_vm._v(_vm._s(setting.name))])]);
  }), 0)]), _c('div', {
    staticClass: "col-md-10"
  }, [_c('div', {
    staticClass: "block block-rounded block-fx-pop",
    attrs: {
      "data-toggle": "appear"
    }
  }, [_c('div', {
    staticClass: "block-content block-content-full"
  }, [_c('div', {
    staticClass: "row mb-3"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('p', {
    staticClass: "font-w600 font-size-h4 mb-3"
  }, [_vm._v(_vm._s(_vm.selectedSetting.name))])]), _c('div', {
    staticClass: "col-md-6 text-right"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.onClickSave
    }
  }, [_vm._v("Save")])])]), _vm.selectedSetting.type === 'array' ? _c('div', _vm._l(_vm.selectedSetting.values, function (setting, index) {
    return _c('div', {
      key: setting.id
    }, [_vm._l(_vm.selectedSetting.formSchema, function (schemaValue, key) {
      return _c('div', {
        key: key
      }, [_c('FormGroup', {
        attrs: {
          "id": `${_vm.selectedSetting._id}-${key}-${index}`,
          "type": schemaValue.type,
          "value": setting[key],
          "label": schemaValue.title,
          "options": schemaValue.options,
          "date-format": schemaValue.dateFormat,
          "date-min-view-mode": schemaValue.dateViewMode
        },
        on: {
          "input": function (val) {
            return _vm.onChangeArrayValue(key, index, val);
          }
        }
      })], 1);
    }), _vm.selectedSetting.name != 'general' ? _c('button', {
      staticClass: "btn btn-danger mb-5",
      on: {
        "click": function (value) {
          return _vm.handleDelete(value, setting);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-ban fa-fw"
    }), _vm._v(" Delete ")]) : _vm._e()], 2);
  }), 0) : _vm.selectedSetting.type === 'object' ? _c('div', _vm._l(_vm.selectedSetting.values, function (value, key) {
    return _c('div', {
      key: key
    }, [_c('FormGroup', {
      attrs: {
        "id": `${_vm.selectedSetting._id}-${_vm.selectedSetting.formSchema[key].title}`,
        "type": _vm.selectedSetting.formSchema[key].type,
        "value": value,
        "label": _vm.selectedSetting.formSchema[key].title,
        "options": _vm.selectedSetting.formSchema[key].options,
        "date-format": _vm.selectedSetting.formSchema[key].dateFormat,
        "date-min-view-mode": _vm.selectedSetting.formSchema[key].dateViewMode
      },
      on: {
        "input": function (val) {
          return _vm.onChangeValue(key, val);
        }
      }
    })], 1);
  }), 0) : _vm._e()])])])]), _c('ConfirmModal', {
    attrs: {
      "prevent": "",
      "open": _vm.modals[_vm.selectedSetting.name],
      "title": 'Create new ' + _vm.selectedSetting.friendlyName + ' setting',
      "text": 'Create new ' + _vm.selectedSetting.friendlyName + ' setting'
    },
    on: {
      "close": function ($event) {
        _vm.modals[_vm.selectedSetting.name] = false;
      },
      "submit": _vm.onSubmitNew
    }
  }, [_vm.loadingAction.update ? _c('Spinner', [_vm._v("Updating...")]) : _c('div', [_vm.selectedSetting.name === 'general' ? _c('div', _vm._l(_vm.selectedSetting.create, function (createValue, key) {
    return _c('div', {
      key: key
    }, [_c('FormGroup', {
      attrs: {
        "id": createValue.title + _vm.selectedSetting.id,
        "type": createValue.type,
        "label": createValue.title,
        "options": createValue.options,
        "date-format": createValue.dateFormat,
        "date-min-view-mode": createValue.dateViewMode
      },
      on: {
        "input": function (value) {
          return _vm.handleNewInput(value, key, createValue.options);
        }
      }
    })], 1);
  }), 0) : _vm._l(_vm.selectedSetting.formSchema, function (schemaValue, key) {
    return _c('div', {
      key: key
    }, [_c('FormGroup', {
      attrs: {
        "id": schemaValue.title + _vm.selectedSetting.id,
        "type": schemaValue.type,
        "label": schemaValue.title,
        "options": schemaValue.options,
        "date-format": schemaValue.dateFormat,
        "date-min-view-mode": schemaValue.dateViewMode
      },
      on: {
        "input": function (value) {
          return _vm.handleNewInput(value, key, schemaValue.options);
        }
      }
    })], 1);
  })], 2)], 1), _c('ConfirmModal', {
    attrs: {
      "prevent": "",
      "open": _vm.modals.delete,
      "title": "Delete",
      "text": "Are you sure?"
    },
    on: {
      "close": function ($event) {
        _vm.modals.delete = false;
      },
      "submit": _vm.onSubmitDelete
    }
  }, [_vm.loadingAction.update ? _c('Spinner', [_vm._v("Deleting...")]) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }